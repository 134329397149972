<template>
  <div class="login" ref="login">
    <q-card class="login__card --emphasize">
      <q-card-section class="text-center">
        <div class="flex items-center justify-center">
          <img
            src="@/assets/images/logos/jumpfeed-full-dark.svg"
            style="width: 185px"
            class="q-my-md"
          />
          <q-separator vertical inset color="dark" class="q-mx-md" />
          <h5 class="q-my-none text-weight-bold">Dashboard</h5>
        </div>
      </q-card-section>

      <q-card-section class="q-py-none">
        <p class="q-mb-none text-weight-medium">
          Enter your account information below to sign in.
        </p>
      </q-card-section>

      <q-card-section tag="section">
        <q-form class="login__form" @submit="validateLogin">
          <div class="row q-col-gutter-y-sm q-col-gutter-x-md">
            <div class="col-12">
              <q-input
                filled
                v-model="userCredentials.email"
                label="Email Address"
                lazy-rules
                :rules="[
                  val =>
                    (val && val.length > 0) ||
                    'Please enter your Email Address.'
                ]"
                :disable="validatingLogin"
                :error="invalidLoginMessage !== ''"
                @focus="invalidLoginMessage = ''"
              />
            </div>
            <div class="col-12">
              <q-input
                filled
                type="password"
                v-model="userCredentials.password"
                label="Password"
                lazy-rules
                :rules="[
                  val =>
                    (val && val.length > 0) || 'Please enter your Password.'
                ]"
                :disable="validatingLogin"
                :error-message="invalidLoginMessage"
                :error="invalidLoginMessage !== ''"
                @focus="invalidLoginMessage = ''"
              />
            </div>
          </div>

          <div class="login__nav-container q-mt-sm">
            <router-link to="/" style="text-decoration: none; color: #999">
              Forgot your password?
            </router-link>
            <q-btn
              label="Sign In"
              type="submit"
              color="primary"
              :disable="validatingLogin"
              :loading="validatingLogin"
            />
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      userCredentials: {
        email: "",
        password: ""
      },
      validatingLogin: false,
      invalidLoginMessage: ""
    };
  },
  methods: {
    validateLogin() {
      this.validatingLogin = true;

      this.$store
        .dispatch("auth/REQUEST", this.userCredentials)
        .catch(() => {
          this.invalidLoginMessage = "The credentials you entered are invalid.";
        })
        .finally(() => {
          this.validatingLogin = false;
          this.$router.push("/");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  @include display-flex(column, center, center);
  height: 100vh;
  min-height: 560px;

  .login__card {
    width: 500px;
  }

  .login__nav-container {
    @include display-flex(row, space-between, center);
  }
}
</style>
